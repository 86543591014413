import { createStore } from 'vuex'
import axios from 'axios'
import i18n from '../main';

export default createStore({
  state: {
    params: {},
    linguaCorrente: "en",
    selectedListingUrl: "",
    newFont: "",
    rootUrl: "https://www.tligwebapp.com/", // "https://www.vvdapp.it/",
    randomAdr: "",
    currentDir: "",
    toolbarActive: null,
    wpPostID: "",
    pageTitle: "",
    itemSizes: [
      { size: '140%' }, // 22px
      { size: '125%' }, // 20px
      { size: '100%' }, // 16px
      { size: '80%' }   // 12px
    ],
    categoriesList: [],
    postsList: [],
    menuJSON: [],
    expandedKeysSave: {},
    postDisplay: {},
    titleDisplay: "",
    db: null,
    allMessages: {},
    messagesIds: [],
    linkAdr: "",
    txtNavCenter: false,
    progressBarValue: 0,
  },
  mutations: {
    SET_PARAMS( state, val ) { state.params = val; },
    SET_PARAM_OPEN( state, val ) { state.paramOpen = val; },

    SET_NEWFONT( state, val ) { state.newFont = val; },
    SET_RANDOM_ADR( state, val ) { state.randomAdr = val; },
    SET_TOOLBAR_ACTIVE( state, val ) { state.toolbarActive = val; },
    SET_CURRENT_DIR( state, val ) { state.currentDir = val; },
    SET_DB( state, val ) { state.db = val; },
    SET_ALL_MESSAGES( state, val ) { state.allMessages = val; },
    SET_MESSAGES_IDS( state, val ) { state.messagesIds = val; },
    SET_LINK_ADR( state, val ) { state.linkAdr = val; },

    SET_WP_PARAM( state, val ) { state.wpParam = val; },
    SET_WP_POSTID( state, val ) { state.wpPostID = val; },

    SET_PAGETITLE( state, val ) { state.pageTitle = val; },
    SET_LINGUA_CORRENTE( state, val ) { state.linguaCorrente = val; },

    SET_POST_DISPLAY( state, val ) { state.postDisplay = val; },
    SET_TITLE_DISPLAY( state, val ) { state.titleDisplay = val; },
    SET_TXT_NAV_CENTER( state, val ) { state.txtNavCenter = val; },
    SET_EXPANDED_KEYS_SAVE( state, val ) { state.expandedKeysSave = val; },

    SET_CATEGORIES_LIST( state, val ) { state.categoriesList = val; },
    SET_POSTS_LIST( state, val ) { state.postsList = val; },
    SET_MENU_JSON( state, val ) { state.menuJSON = val; },
    SET_PROGRESS_BAR_VALUE( state, val ) { state.progressBarValue = val; },

  },
  actions: {
    // GET TRANSLATIONS
//    getTranslations({ commit, state }) {
//      axios.get('https://www.tligwebapp.com/locales/translations.json' )
//        .then(response => {
//          console.log('langue anglaise chargée ', response.data);
//          state.messagesLoaded = response.data
//      })
//    },
    getParams({ commit }) {
      axios.get('https://www.cosatti.net/tlig/parameters.json')
          .then(response => {
              commit( 'SET_PARAMS', response.data )
              commit( 'SET_PARAM_OPEN', true )
         })
    },

    async getMessages({ commit, state }) {
      console.log('begin getMessages')
      axios.get('https://ww3.tlig.org/wp-content/includes/php/readMessages.php', {
          onDownloadProgress: (progressEvent) => {
            console.log('total ', progressEvent);
            //console.log(progressEvent.target._response.length) // loaded
            //console.log(progressEvent.target.responseHeaders['Content-Length'])

            const { loaded, total } = progressEvent;
            //const total = 3705826;
            const percentCompleted = Math.floor((loaded / total) * 100);
//            const percentCompleted = total ? Math.floor((loaded / total) * 100) : null;

            //let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            //let percentCompleted = Math.round((progressEvent.loaded * 100) / 6570722);
            //console.log('eventRequest = ', progressEvent.event.srcElement.getResponseHeader('Access-Control-Expose-Headers'));
            commit( 'SET_PROGRESS_BAR_VALUE', percentCompleted );
            console.log('pourcent2 = ', percentCompleted)
          },
          params: { lang: state.linguaCorrente },
          headers: { 'Accept-Encoding': 'gzip' }  })
          .then(response => {
              console.log( 'getMessages normal = ', response.data );
              console.log( 'length ', response.data.length);
              commit( 'SET_MESSAGES_IDS', response.data );
          })
          .catch(error => {
            if (error.response) {
              // The server responded with a status code outside the 2xx range
              console.log('Error response:', error.response);
            } else if (error.request) {
              // The request was made but no response was received
              console.log('Error request:', error.request);
            } else {
              // Something happened in setting up the request that triggered an error
              console.log('Error message:', error.message);
            } 
          })     
    },
  /*  async getMessagesOld({ commit, state }) {
      //axios.get('http://www.tlig.org/php/readMessagesOld.php', { 
      axios.get('https://ww3.tlig.org/wp-content/includes/php/readMessagesOld.php', { 
          onDownloadProgress: (progressEvent) => {
            console.log('total ', progressEvent);
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            // console.log(percentCompleted);
            commit( 'SET_PROGRESS_BAR_VALUE', percentCompleted );
          },
          params: { lang: state.linguaCorrente } })
          .then(response => {
              console.log( 'getMessagesOLD = ', response.data );
              commit( 'SET_MESSAGES_IDS', response.data );
      })
    }, */

    getRandom({ commit }) {
      console.log('entre dans getRandom, lang = ', i18n.global.t('random_message_site'));
      var site = i18n.global.t('random_message_site');
      axios.get('https://ww3.tlig.org/wp-content/includes/php/random.php')
          .then(response => {
              console.log( 'getRandom = ', response.data );
              commit( 'SET_RANDOM_ADR', site + response.data );
      })
    },
    // GET A SPECIFIC POST ACCORDING TO THE ID
    getPost({ commit, state }) {
      console.log('enter getPost and wpPostID = ', state.wpPostID )
      axios.get('https://www.tligwebapp.com/wordpress/?rest_route=/wp/v2/posts/' + state.wpPostID )
        .then(response => {
          commit( 'SET_POST_DISPLAY', response.data );
          console.log( 'postDisplay ', state.postDisplay );
      })
    },
    // GET ALL CATEGORIES AND ALL ARTICLES
    getCatPostList({ commit, state }) {
      var donneesCat = [];
      var promisesCat = [];
      var nbrPagesCat = 0;
      console.log('entrée dans getCatPostList')
      // Get how many pages of Categories
      axios.get('https://www.tligwebapp.com/wordpress/?rest_route=/wp/v2/categories&_fields=id&per_page=100' )
        .then(response => {
          nbrPagesCat = response.headers['x-wp-totalpages'];
          console.log('nbrPagesCat = ', nbrPagesCat)

          // For each pages of Categories
          for (var i = 0; i < nbrPagesCat; i++) {
            promisesCat.push(
              axios.get('https://www.tligwebapp.com/wordpress/?rest_route=/wp/v2/categories&_fields=id,parent,description,slug,name&per_page=100' )
                .then(response => {
                  donneesCat = donneesCat.concat(response.data)
                  console.log('donneesCat = ', donneesCat )
                })
            )
          }
          Promise.all( promisesCat ).then(() => { // execute all axios promises

            var donneesPost = [];
            var promisesPost = [];
            var nbrPagesPost = 0;
            // Get how many pages of Categories
            axios.get('https://www.tligwebapp.com/wordpress/?rest_route=/wp/v2/posts&_fields=id&per_page=100')
              .then(response => {
                nbrPagesPost = response.headers['x-wp-totalpages'];
                // console.log ('post x-wp-totalpages = ', nbrPagesPost)

              for (var i = 0; i < nbrPagesPost; i++) {
                promisesPost.push(
                  axios.get('https://www.tligwebapp.com/wordpress/?rest_route=/wp/v2/posts&_fields=id,title,categories&per_page=100' )
                    .then(response => {
                      donneesPost = donneesPost.concat(response.data)
                      console.log('donneesPost = ', donneesPost)
                    })
                )
              }
              Promise.all( promisesPost ).then(() => {
                // sort the categories
                console.log('données avant tri = ', donneesCat)
                //console.log('posts avant tri = ', donneesPost)
                donneesCat.sort((a, b) => b.slug.localeCompare(a.slug));
                donneesPost.sort((a, b) => a.title.rendered.localeCompare(b.title.rendered));
                console.log('données après tri = ', donneesCat)
                commit( 'SET_CATEGORIES_LIST', donneesCat );
                commit( 'SET_POSTS_LIST', donneesPost );
                console.log( 'CATEGORIES_LIST = ', state.postsList )
                // console.log( 'POSTS_LIST = ', state.postsList )
                
              })
            });
      
          });
        })
    },
  }
})
