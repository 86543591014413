<template>
    <div class="topToolbars">

        <!--***************************** 
        *** NAVIGATION BAR AT THE TOP *** 
        *** ************************* ***-->
        <Button class="sticky navbar">

            <!-- BUTTON BACK -->
            <div class="leftButtonsBlock">
                <Button @click="this.$router.back()" icon="pi pi-chevron-left" class="backArrow" />
                <div @click="this.$router.back()">{{ this.back }}</div>
            </div>

            <!-- TITLE -->
            <div :style="{visibility: this.$store.state.txtNavCenter ? 'visible' : 'hidden'}" class="texteMilieu">
                <div class="flex justifyContentCenter">
                    <i @click="this.$parent.prev()" class="pi pi-chevron-left chevron" style="font-size: 16px !important; "></i>
                    <div v-html="this.title"></div> 
                    <i @click="this.$parent.next()" class="pi pi-chevron-right chevron" style="font-size: 16px !important; "></i>
                </div>
            </div>

            <!-- SEARCH & HAMBURGER BUTTON -->
            <div class="hamburger search-hamburger">
                <a href="" @click.stop.prevent="this.$router.push('/search')" >
                    <i style="color: white" class="pi pi-search" />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="" @click.stop.prevent="visibleRight = true" >
                    <i style="color: white" class="pi pi-bars p-toolbar-separator p-mr-2" />
                </a>
            </div>

        </Button>

        <!--************************** 
        *** MENU BAR AT THE BOTTOM *** 
        *** ********************** ***-->
        <div class="sticky toolbar">
            
            <div v-for="(item, index) in this.$i18n.messages[this.$store.state.linguaCorrente].menuBottom" 
                :key="index"
            >
                <router-link 
                    :to="item.route" 
                    @click="setTheToolbarActive(item, index)"
                    class="top-toolbar-link" 
                >
                    <i class="material-icons" style="font-size:36px">{{ this.$i18n.messages[this.$store.state.linguaCorrente].menuBottom[index].icon }} </i>
                    <div class="pi-label">{{ item.label }}</div>
                </router-link>

            </div>
        </div>

        <Sidebar 
            v-model:visible="visibleRight" 
            :baseZIndex="1000" 
            position="right" 
            @hide="leave"
            >
            <!-- LOGO -->
            <div>
                <div class="sidebar-logo"><img alt="Vue logo" src="/img/logo.png" width="80"/></div>
            </div>

            <div class="dropdownSidebar">
                <!-- LANGUAGES DROPDOWN -->
                <Dropdown 
                    v-model="selectedLang" 
                    :options="optionsDropdown" 
                    optionLabel="name" 
                    tabindex=0
                    @change="changeLang">
                    
                    <template #value="slotProps">
                        <div class="country-item" v-if="slotProps.value">
                            <img :src="this.$store.state.rootUrl + 'img/flags/' + slotProps.value.code.toLowerCase() + '.png'" class="flag" />&nbsp;
                            <span>{{slotProps.value.name}}</span>
                        </div>
                    </template>
                    <template #option="slotProps">
                        <div class="country-item">
                            <img :src="this.$store.state.rootUrl + 'img/flags/' + slotProps.option.code.toLowerCase() + '.png'" class="flag" />&nbsp;
                            <span>{{slotProps.option.name}}</span>
                        </div>
                    </template>
                </Dropdown>
                <!-- CHARACTER SIZE BUTTON -->
                <div class="charSizeButton" @click="charSizeToggle" >Aa</div>
            </div>
            <!-- CHARACTER SIZE OPTIONS -->
            <div>
                <div v-if="optionsDisplay" id="charSizeBox">
                    <hr class="ligneSeparation" noshade width="100%" size="1">
                    <div class="charSizeTitle">{{ this.$t("title_char_size") }}</div>
                    <div class="charSizeOptions">
                        <div v-for="(item, index) in this.$store.state.itemSizes" 
                            :key="item.size"
                            @click="changeSize(index)" 
                            :class="{ charSizeSel: charSizeOnOff[index] }" 
                            :style="{ 'font-size': item.size }"
                        >Aa</div>
                    </div>
                </div>
            </div>
        </Sidebar>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';

export default {
    name: "ToolbarTop",
    components: { Sidebar, Button, Dropdown },
    props: ['back', 'title', 'slotProps'],
	data() {
		return {
            visibleRight: false,
            visibleSearch: false,
            expandedKeys: {},
            optionsDisplay: false,
            charSizeOnOff: [],
            selectedLang: null,
            linguaCorrente: null,
            titre: "",
            menuBottom: {},
        }
	},
    computed: {
        optionsDropdown() {
            var optLang = this.$i18n.messages[this.$store.state.linguaCorrente].langChoice;
            var virgule = ","
            var lang = "[";
            for (var i = 0; i < optLang.length; i++) { 
                var theCode = this.$t("langChoice[" + i + "].code");
                var theName = this.$t("langChoice[" + i + "].name"); 
                if ( i == optLang.length - 1 ) virgule = "";
                lang += "{\"name\": \"" + theName + "\", \"code\": \"" + theCode + "\"}" + virgule;
            } 
            lang += "]";
            return JSON.parse(lang);
        },
        ...mapState(['titleDisplay', 'txtNavCenter'])
    },
    watch: {
        titleDisplay () {
            console.log('titledisplay = ', this.$store.state.titleDisplay)
            this.titre = this.$store.state.titleDisplay;
        },
        txtNavCenter() {
            console.log("txtnavcenter ", this.$store.state.txtNavCenter)
        }
    },
    methods: {
        changeLang(ev1) { 
            localStorage.lang = ev1.value.code.toLowerCase();
            this.$i18n.locale = ev1.value.code.toLowerCase();
            this.$store.commit( 'SET_PRAYERS_OPEN', false );
            this.$store.commit( 'SET_EXTRACTS_OPEN', false );
            //this.$router.push('/');
            window.location.assign("/");
        },
        leave() { 
            this.optionsDisplay = false;
        },
        goBack() { this.$router.back(); },
        changeSize(offset) {
            this.charSizeOnOff.forEach((element, index) => { 
                if ( index == offset ) {
                    console.log( 'index dans changeSize = ', index)
                    this.charSizeOnOff[index] = true; 
                    localStorage.charSize = index; 
                    this.$store.commit( 'SET_NEWFONT', this.$store.state.itemSizes[offset].size )
                    console.log('la charSize dans changeSize = ', localStorage.charSize)
//                    this.newFontClass = "newFontSize-" +  this.$store.state.newFont.substr(0, 3) 
                }
                else {
                    this.charSizeOnOff[index] = false;
                }
            })
        },
        charSizeToggle() { this.optionsDisplay = !this.optionsDisplay },
        setTheToolbarActive( item, index ) { 

            console.log( "item.name = ", item.name )
            if ( item.name == 'link' ) {
                this.$store.commit( 'SET_LINK_ADR', item.param ); }
            else {
                this.$store.commit( 'SET_LINK_ADR', "" );
            }
            console.log( "linkAdr = ", this.$store.state.linkAdr )
            this.$store.commit( 'SET_TOOLBAR_ACTIVE', index ) 
            console.log( "toolbaractive après click = ", this.$store.state.toolbarActive )
        },
    },
    created() {
        var selLang = this.$i18n.messages[this.$store.state.linguaCorrente].langChoice;
        for (var i = 0; i < selLang.length; i++) { 
            if ( this.$t("langChoice[" + i + "].code").toLowerCase() == this.$store.state.linguaCorrente ) {
                var codeLang = this.$t("langChoice[" + i + "].code");
                var nameLang = this.$t("langChoice[" + i + "].name");
            }
        } 
        var selL = "{\"name\": \"" + nameLang + "\", \"code\": \"" + codeLang + "\"}";  // {name: 'English', code: 'EN'}
        this.selectedLang = JSON.parse(selL);
        console.log('selectedLang = ', this.selectedLang)
    },
    mounted() {
        console.log( 'language dans toolbartop   = ', this.$store.state.linguaCorrente )

        for (var i = 0; i < this.$store.state.itemSizes.length; i++) { this.charSizeOnOff.push(false); } // initialise tableau à false (4 éléments
        console.log('localstorage.charSize = ', localStorage.charSize)
        console.log('this.charSizeOnOff = ', this.charSizeOnOff)
        if ( localStorage.charSize ) {
            this.charSizeOnOff[localStorage.charSize] = true;
            this.$store.commit( 'SET_NEWFONT', this.$store.state.itemSizes[localStorage.charSize].size )
             }
        else {
            this.charSizeOnOff[2] = true;
            this.$store.commit( 'SET_NEWFONT', this.$store.state.itemSizes[2].size )
        }
    }
}
</script>

