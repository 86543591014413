import { createApp } from 'vue'
import App from './App.vue'
import Router from './router'
import Store from './store'
import PrimeVue from 'primevue/config'
import { createI18n } from 'vue-i18n'
import InstantSearch from 'vue-instantsearch/vue3/es';

import './assets/css/styles.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import Tooltip from 'primevue/tooltip'

import en from '../locales/en.json'
import it from '../locales/it.json'
import fr from '../locales/fr.json'

const languages = { it: it, en: en, fr: fr }
const messages = Object.assign(languages)

const fallBackLang = 'en';
const langBrowser = navigator.language.substr(0, 2); // langue du navigateur
const navLanguageOk = Object.prototype.hasOwnProperty.call(messages, langBrowser); // look if the navigator language is one of the present languages
if ( navLanguageOk ) { // si c'est une langue prévue on regarde si localStorage a une valeur sinon la langue du browser
  var langDefault = localStorage.lang ? localStorage.lang : langBrowser; }
else { // si ce n'est pas une langue prévue on regarde si localStorage a une valeur sinon l'anglais comme défault
  langDefault = localStorage.lang ? localStorage.lang : fallBackLang; 
}
localStorage.lang = langDefault;

const i18n = createI18n({
  locale: langDefault,
  fallbackLocale: fallBackLang,
  messages
})
export default i18n;

const app = createApp(App)
app.use(Store)
app.use(Router)
app.use(PrimeVue)
app.use(i18n)
app.directive('tooltip', Tooltip)
app.use(InstantSearch)

app.mount('#app')
