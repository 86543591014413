<template>
  <div class="home" v-if="paramOpen">
    <!-- @ is an alias to /src -->
    <img alt="Vue logo" src="/img/logo.png" width="80"/>
    <div class="titre-principal">{{ this.$t('appTitle') }}</div>
    <div class="dropdownHome">
      <div id="buttonBlock" class="extCollButton">
          <Button type="button" icon="pi pi-plus" @click="expandAll(this.$store.state.menuJSON)" v-tooltip.top="{ value: expAll }" class="p-button-text" />&nbsp;
          <Button type="button" icon="pi pi-minus" @click="collapseAll" v-tooltip.top="{ value: collAll }" class="p-button-text" />&nbsp;
          <Button type="button" icon="pi pi-search" @click="displaySearchBox" v-tooltip.top="{ value: displaySearch }" class="p-button-text" />
      </div>

      <Dropdown 
          v-model="selectedLang" 
          :options="optionsDropdown" 
          optionLabel="name" 
          tabindex=0
          @change="changeLang">
          
          <template #value="slotProps">
              <div class="country-item" v-if="slotProps.value">
                  <img :src="this.$store.state.rootUrl + 'img/flags/' + slotProps.value.code.toLowerCase() + '.png'" class="flag" />&nbsp;
              </div>
          </template>
          <template #option="slotProps">
              <div class="country-item">
                  <img :src="this.$store.state.rootUrl + 'img/flags/' + slotProps.option.code.toLowerCase() + '.png'" class="flag" />&nbsp;
                  <span>{{slotProps.option.name}}</span>
              </div>
          </template>
      </Dropdown>
    </div>

    <div v-if="Object.keys(this.$store.state.menuJSON).length === 0"><img src="/img/wheel.gif"></div>
    <div v-else>
      <Tree selectionMode="single" :value="this.$store.state.menuJSON" :filter="true" filterMode="lenient"
          @nodeSelect="onNodeSelect" @nodeUnselect="onNodeUnselect" :expandedKeys="expandedKeys"
          @nodeExpand="espandiNodo">
      </Tree>

      <!-- READ MESSAGES -->
      <Card @click="displayMessages">
          <template #content>
              <div>{{ this.$t('readMessages') }}</div>
          </template>
      </Card>
      <!-- RANDOM MESSAGE -->
      <Card @click="displayRandom">
          <template #content>
              <div>{{ this.$t('random') }}</div>
          </template>
      </Card>
      <!-- PUBBLICAZIONI -->
      <Card @click="displayLink(this.$t('publications_url'))">
          <template #content>
              <div>{{ this.$t('publications') }}</div>
          </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Dropdown from 'primevue/dropdown';
import Tree from 'primevue/tree';
import Button from 'primevue/button';
import Card from 'primevue/card';
import $ from 'jquery';

export default {
  name: "home-page",
  components: { Dropdown, Tree, Button, Card },
	data() {
		return {
      linguaCorrente: null,
      selectedLang: null,
      expandedKeys: {},
      loading: true,
    }
	},
  computed: {
    collAll() { return this.$t("collapse_all"); },
    expAll() { return this.$t("expand_all"); },
    displaySearch() { return this.$t("display_search"); },
    ...mapState(['randomAdr', 'paramOpen', 'cats', 'messagesLoaded', 'linkAdr']),
    optionsDropdown() {
      console.log( "linguaCorrente = ", this.$store.state.linguaCorrente); console.log( "langchoice = ", this.$i18n.messages[this.$store.state.linguaCorrente])
      var optLang = this.$i18n.messages[this.$store.state.linguaCorrente].langChoice;
      var virgule = ","
      var lang = "[";
      console.log( 'optLang = ', optLang )
      for (var i = 0; i < optLang.length; i++) { 
        var theCode = this.$t("langChoice[" + i + "].code");
        var theName = this.$t("langChoice[" + i + "].name"); 
        if ( i == optLang.length - 1 ) virgule = "";
        lang += "{\"name\": \"" + theName + "\", \"code\": \"" + theCode + "\"}" + virgule;
      } 
      lang += "]";
      console.log( 'lang = ', lang )
      return JSON.parse(lang);
    },
  },
  methods: {
    expandAll(theNodes) {
        for (let node of theNodes) {
            this.expandNode(node, true);
        }
        this.expandedKeys = {...this.expandedKeys};
        this.$store.commit( 'SET_EXPANDED_KEYS_SAVE', this.expandedKeys )
        $('.p-inputtext').focus();
    },
    collapseAll() {
      this.expandedKeys = {};
      this.$store.commit( 'SET_EXPANDED_KEYS_SAVE', this.expandedKeys )
      $('.p-inputtext').focus();
    },
    displaySearchBox() {
      var dispSearch = $('.p-tree-filter-container').css('display');
      console.log( 'display = ', dispSearch)
      if ( dispSearch === 'none' ) {
        $('.p-tree-filter-container').attr('style', 'display: block !important'); 
        $('.p-inputtext').focus(); }
      else {
        $('.p-tree-filter-container').attr('style', 'display: none !important');
      }
    },
    collapseNode( node ) {
        this.expandedKeys[node.key] = false;
        this.$store.commit( 'SET_EXPANDED_KEYS_SAVE', this.expandedKeys )
    },
    espandiNodo( node ) {
      console.log('espandiNodo...', node.key);
    },
    expandNode( node, childrenAlso ) {
      console.log("j'expande le node");
      if (node.children && node.children.length) {
        if ( !this.expandedKeys[node.key] ) {
          this.expandedKeys[node.key] = true; 
          this.$store.commit( 'SET_EXPANDED_KEYS_SAVE', this.expandedKeys )
        }
        if ( childrenAlso ) {
          this.expandAll(node.children);
        }
      }
    },
    onNodeSelect(node) {
      console.log('node = ', node)
      console.log( 'this.expandedKeys = ', this.expandedKeys)
      if ( this.expandedKeys[node.key] ) {
        this.collapseNode(node);
        console.log('déjà expanded');
        return;
      }
      if ( node.data == 'NODE' ) {
        console.log("c'est un node");
        this.expandNode(node, false); }
      else {
        if ( node.data != 'NODOCS' ) {
          console.log('Article selected');
          this.$router.push({ name: 'Display', params: { postId: node.data } });
          localStorage.lastPost = node.data;
        }
      }
    },
    onNodeUnselect(node) {
        this.collapseNode(node);
    },
    changeLang(ev1) {
        localStorage.lang = ev1.value.code.toLowerCase();
        this.$i18n.locale = ev1.value.code.toLowerCase();
        //this.$router.push('/');
        window.location.assign("/");
    },
    displayMessages() {
      console.log('displayMessages');
      this.$router.push('/messages')
    },
    displayRandom() {
      console.log('displayRandom');
      this.$router.push('/random')
    },
    displayLink(linkAdresse) {
      this.$store.commit( 'SET_LINK_ADR', linkAdresse );
      this.$router.push( '/link' )
    },
  },
  watch: {
    // when random message link has been selected
    randomAdr () {
      window.open( this.$store.state.randomAdr, '_self' );
      //this.$router.back('Home');
    },
  },
  created() {
    console.log( "linguaCorrente in Home created = ", this.$store.state.linguaCorrente )

    var selLang = this.$i18n.messages[this.$store.state.linguaCorrente].langChoice;
    for (var i = 0; i < selLang.length; i++) { 
        if ( this.$t("langChoice[" + i + "].code").toLowerCase() == this.$store.state.linguaCorrente ) {
            var codeLang = this.$t("langChoice[" + i + "].code");
            var nameLang = this.$t("langChoice[" + i + "].name");
        }
    } 
    var selL = "{\"name\": \"" + nameLang + "\", \"code\": \"" + codeLang + "\"}";  // {name: 'English', code: 'EN'}
    this.selectedLang = JSON.parse(selL);

    // Initialisation de expandedKeys
    this.expandedKeys = this.$store.state.expandedKeysSave;
  }
};
</script>
