<template @keyup="keydown1()">
  <div class="post" :style="{ '--newFontSize' : this.$store.state.newFont }">
    <ToolbarTop :back="this.$t('homeLabel')" :title="this.$t('listingLabel')"></ToolbarTop>

      <!-- Select a Message -->
      <div class="loadingMessages" v-if="(!afficherMessagesList)">
        <div class="loadingMessagesText"> {{this.$t('loadingMessagesLabel')}}...</div>
        <!--<ProgressBar :value="this.$store.state.progressBarValue"/>-->
      </div>
      <div class="marginBottom100 paddingLR15" v-if="afficherMessagesList">
        <div class="stickyYearMonth">
          <div class="dropAnnee" style="font-size: 30px;">
            <div class="chevronWrapper">
              <i v-if="greaterFirstYear" class="pi pi-chevron-left chevron" @click="clickChevron('gauche')" style="font-size: 30px !important; "></i>
            </div>
            <Dropdown v-model="selectedYear" tabindex=0 :options="this.anneesMessages" optionValue="year" optionLabel="year" @change="changedYear" placeholder="Select a YEAR" />
            <div class="chevronWrapper">
              <i v-if="smallerLastYear" class="pi pi-chevron-right chevron" @click="clickChevron('droite')" style="font-size: 30px !important; "></i>
            </div>
          </div>
          <div>
            <div style="display: inline-block" v-for="(item, index) in this.monthsMessages" :key="index">
              <div :class="[this.monthSel[index] ? 'monthsListSelected' : 'monthsList']" @click="selectDays(item.month, index)">{{ item.month }}</div>            
            </div>
          </div>
        </div>
        <div v-if="(this.daysMessages.length > 0)" class="displayMoisAnnee" v-html="displayMoisAnnee(this.selectedYear, this.selectedMonth)"></div>
        <div class="jours" v-for="(item, index) in this.daysMessages" :key="index">
          <div class="displayJour"><a @click="afficheSingleMessage(item)" v-html="displayJours(item)"></a></div>
          <div class="displayTitle"><a @click="afficheSingleMessage(item)" v-html="item.title"></a></div>
        </div>
      </div>
  </div>

</template>

<script>
import { mapState } from 'vuex';
import ToolbarTop from '@/components/ToolbarTop.vue';
import Dropdown from 'primevue/dropdown';
//import ProgressBar from 'primevue/progressbar'
import $ from 'jquery';

export default {
  name: "messages-listing",
//  components: { ToolbarTop, Dropdown, ProgressBar },
  components: { ToolbarTop, Dropdown },
	data() {
		return {
      afficherMessagesList: true,
      messagesContent: "",
      tousLesMessages: null,
      yearMonth: [],
      years: [],
      selectedYear: null,
      anneesMessages: [],
      selectedMonth: null,
      monthsMessages: [],
      monthSel: [],
      selectedDays: null,
      daysMessages: [],
      optionLabelYear: 'year',
      optionLabelMonth: 'month',
      firstYear: null,
      lastYear: null,
      actualYear: null,
      valuePosition: null,
      messageAAfficher: {
        "titre": String,
        "content": String,
      },
    }
	},
  computed: {
    ...mapState(['db', 'messagesIds', 'txtNavCenter']),
    greaterFirstYear() {
      return this.selectedYear > this.firstYear;
    },
    smallerLastYear() {
      return this.selectedYear < this.lastYear;
    },
  },
  methods: {
    keydown1() {
      console.log('intercepté')
    },
    afficheSingleMessage(item) {
      this.$router.push({ name: 'MessagesDisplay', params: { messageItem: JSON.stringify(item) } });
    },
    clickChevron(direction) {
      if ( direction == 'gauche' ) var minusPlus = -1; else minusPlus = 1;
      this.selectedYear = ( Number(this.selectedYear) + minusPlus ).toString();
      this.changedYear(); 
    },
    displayMoisAnnee(selectedYear, selectedMonth) {
      console.log('selectedyear = ', selectedYear)
      console.log('linguaCorrente = ', this.$store.state.linguaCorrente)
      let label = this.$i18n.messages[this.$store.state.linguaCorrente].moisLabel[Number(selectedMonth - 1)] + " " + selectedYear;
      return label;
    },
    displayJours(item) {
      let jour = item.date.substr(8, 2);
      let label = " <span class='jourNum'>" + Number(jour) + "</span>";
      return label;
    },
    changedYear() {
      this.monthsMessages = [];
      this.daysMessages = [];
      this.monthSel = [];
      console.log('stringify = ', JSON.stringify(this.selectedYear))
      this.yearMonth.forEach((item) => {
        if ( this.selectedYear == item.ym.substr(0, 4) ) {
          this.monthsMessages.push({"month": item.ym.substr(4, 2) })
        }
      })
      this.selectedMonth = "";
      this.actualYear = this.selectedYear;
      this.selectDays(this.monthsMessages[0].month, 0); // select the days of the first month
    },
    selectDays(monthSelected, index) {
      console.log('montn = ', monthSelected, ' index = ', index)
      this.selectedMonth = monthSelected;
      this.monthSel = [];
      this.monthSel[Number(index)] = true;
      this.daysMessages = [];
      this.yearMonth.forEach((item) => {
        if ( this.selectedYear + this.selectedMonth == item.ym ) {
          item.days.forEach((item2) => {
            this.getOneMessage(this.selectedYear + "-" + this.selectedMonth + "-" + item2)
            .then(response => {
              this.daysMessages.push(response)
              console.log('response du message = ', response)
            })
          })
        }
      })
    },
    async getDb() {
      return new Promise((resolve) => {

        console.log('la langue = ', this.$store.state.linguaCorrente)
        let openRequest = window.indexedDB.open("messages-" + this.$store.state.linguaCorrente, 1);
        
        openRequest.onupgradeneeded = e => {
          console.log('onupgradeneeded');
          let dbTemp = e.target.result;
          // on crée le magasin "messages" 
          let mess = dbTemp.createObjectStore('messages', {keyPath: 'id'}); 
          let index = mess.createIndex('date_message', 'date'); // eslint-disable-line
        };

        openRequest.onsuccess = e => {
          resolve(e.target.result);
        };
        
      });
    },
    async getMessagesFromDb() {
      return new Promise((resolve) => {
        
        let transaction = this.$store.state.db.transaction("messages", "readonly");
        let messages = transaction.objectStore("messages"); // obtenir le magasin d'objets pour opérer dessus

        // LECTURE ET TRI DE TOUS LES MESSAGES
        this.tousLesMessages = messages.getAll();
        this.tousLesMessages.onsuccess = () => {
          this.tousLesMessages.result.sort((a, b) => a.date.localeCompare(b.date));

          console.log('touslesmessages.result = ', this.tousLesMessages.result)
          this.$store.commit( 'SET_ALL_MESSAGES', this.tousLesMessages.result )
          // FORMATTE PAR DATE
          var yearBreak = ""; var yearFirst = true;
          var monthBreak = ""; var monthFirst = true;
          var monthsOfTheYear = []; 
          var daysOfTheMonth = [];

          for (const i in this.tousLesMessages.result) {
            let dateMess = this.tousLesMessages.result[i].date.substr(0, 10);
            let yearMess = dateMess.substr(0, 4);
            let monthMess = dateMess.substr(5, 2);
            let dayMess = dateMess.substr(8, 2); 

            if ( monthMess != monthBreak || yearMess != yearBreak ) {
              if ( monthMess != monthBreak && !monthFirst ) {
                this.yearMonth.push({"ym": yearBreak + monthBreak, "days": daysOfTheMonth});
                daysOfTheMonth = [];
                monthBreak = monthMess;
              }
              if ( monthFirst ) monthBreak = monthMess;
              if ( yearMess != yearBreak && !yearFirst ) {
                this.years.push({"year": yearBreak, "months": monthsOfTheYear});
                monthsOfTheYear = [];
                yearBreak = yearMess;
              }
              if ( yearFirst ) yearBreak = yearMess;
              monthsOfTheYear.push(monthMess);
              monthFirst = false;
              yearFirst = false;
              
            } 
            daysOfTheMonth.push(dayMess);
          }
          this.yearMonth.push({"ym": yearBreak + monthBreak, "days": daysOfTheMonth});
          this.years.push({"year": yearBreak, "months": monthsOfTheYear});

          console.log( 'years = ', this.years );
          console.log( 'year month = ', this.yearMonth );
        }
        transaction.oncomplete = () => {
          resolve();
        };
      })
    },
    async putMessagesToDb() {
      return new Promise((resolve) => {
        
        let transaction = this.$store.state.db.transaction("messages", "readwrite");
        let messages = transaction.objectStore("messages"); // obtenir le magasin d'objets pour opérer dessus

        let messagesList = this.$store.state.messagesIds;
        console.log('messagesList = ', messagesList);
        for (const theMessage in messagesList) {
          let mess = {
            id: messagesList[theMessage].id,
            title: messagesList[theMessage].title,
            content: messagesList[theMessage].content,
            date: messagesList[theMessage].date
          };
          // console.log('mess = ', mess);
          let request = messages.put(mess); // eslint-disable-line
        }

        transaction.oncomplete = () => {
          resolve();
        };

      })
    },
    async getMessagesCount() {
      return new Promise((resolve) => {

        let transaction = this.$store.state.db.transaction("messages", "readonly");
        let messages = transaction.objectStore("messages"); // obtenir le magasin d'objets pour opérer dessus

        const countRequest = messages.count(); 
        var res = 0;
        countRequest.onsuccess = () => {
          if ( countRequest.result > 0 ) { res = countRequest.result; }
        }

        transaction.oncomplete = () => {
          resolve(res);
        };
        
      });
    },
    async getOneMessage(dateQuery) {
      return new Promise((resolve) => {

        let transaction = this.$store.state.db.transaction("messages", "readonly");
        let messages = transaction.objectStore("messages"); // obtenir le magasin d'objets pour opérer dessus
        let messageIndex = messages.index("date_message");

        let request = messageIndex.get(dateQuery);

        transaction.oncomplete = () => {
          resolve(request.result);
        };
        
      });
    },
  },
  async created() {
    // i18n settings for primevue dropdown
    this.$primevue.config.locale.emptyMessage = this.$t('emptyMessage');

    this.$store.commit( 'SET_DB', await this.getDb() );
    var testDBCount = await this.getMessagesCount();
    if ( testDBCount == 0 ) {
      // get Messages from Server
      this.afficherMessagesList = false;
      console.log('démarrage getMessages');
      var callGetMessages = 'getMessages';
//      if ( this.$store.state.linguaCorrente == 'en' ) { 
//      else {
//        callGetMessages = 'getMessagesOld';
//      }
      this.$store.dispatch( callGetMessages ); }
    else {
      this.afficherMessagesList = true;
     // get Messages from IndexedDB
      this.getMessagesFromDb().then( () => {
        // reformat the years for display
        this.years.forEach((item) => {
          this.anneesMessages.push({"year": item.year })
        })
        this.firstYear =  this.anneesMessages[0].year;
        this.lastYear =  this.anneesMessages[this.anneesMessages.length - 1].year;
        this.selectedYear = this.firstYear;
        this.changedYear(); 
        this.selectDays(this.monthsMessages[0].month, 0);
      })
    }
    $('.chevron').css('visibility', 'hidden');
    this.$store.commit( 'SET_TXT_NAV_CENTER', true )
  },
  watch: {
    // Messages have been read on the server
    messagesIds() { 
      // put Messages into IndexedDB
      this.putMessagesToDb().then( () => {
        this.getMessagesFromDb().then( () => {
          this.afficherMessagesList = true;
          console.log('false 3')
          // reformat the years for display
          this.years.forEach((item) => {
            this.anneesMessages.push({"year": item.year })
          })
          this.firstYear =  this.anneesMessages[0].year;
          this.lastYear =  this.anneesMessages[this.anneesMessages.length - 1].year;
          this.selectedYear = this.firstYear;
          this.changedYear(); 
          this.selectDays(this.monthsMessages[0].month, 0);
        })
      })
    }
  },
} 
</script>

<style>
h2 { margin-bottom: 20px !important; }
.post { margin-top: 36px !important; }
.post p { margin-top: 6px; margin-bottom: 0; }
.titleMessage { text-align: center; }
.loadingMessages { padding-top: 20px; font-size: 20px; text-align: center; }
.loadingMessagesText { animation: blinker 1s linear infinite; }
@keyframes blinker {  50% { opacity: 0; } }
.dropMois { font-size: 20px !important; display: inline-block; min-width: 150px; }
.dropAnnee { display: inline-block; min-width: 180px; margin-top: 15px; }
.p-dropdown .p-drodown-label { border: 2px solid black !important; }
.dropAnnee .p-dropdown:not(.p-disabled).p-focus { border: 2px solid black !important }
.dropAnnee .p-dropdown-label.p-inputtext { padding: 2px 20px !important; min-width: 150px; }
.dropAnnee .p-inputtext { font-size: 25px !important; }

.dropAnnee .p-inputtext.p-placeholder { font-size: 16px !important; }
.dropAnnee .p-dropdown { border-radius: 30px !important; background-color:#4e92ac59 !important; border: 2px solid black !important;}
.dropAnnee .p-dropdown-trigger { display: none; }
.chevron { cursor: pointer; font-weight: normal }
.chevronWrapper { min-width: 30px; display: inline-block; }

.displayJour, .displayTitle { font-size: 18px; display: inline-block; min-width: 36px; cursor: pointer }
.displayMoisAnnee { text-align: left; font-size: 20px; font-weight: bold; margin-bottom: 10px; border-bottom: 1px solid }
.jours { display: flex; text-align: left; margin-bottom: 5px; }
.jourNum { background-color: #ffe27f;
    color: black;
    margin: 2px 0 2px 0;
    padding: 0px 4px;
    border: 1px solid #917455;
    border-radius: 5px;}
.monthsList, .monthsListSelected { cursor: pointer;
    padding: 2px 12px 3px;
    margin: 10px 5px 0;
    background-color: #4e92ac59;
    border-radius: 20px; }
.monthsListSelected { background-color: #4e92ac; color: white; font-weight: bold; }
.stickyYearMonth { position: -webkit-sticky; position: sticky; top: 36px; background-color: white; padding-bottom: 10px; text-align: center;  }

.p-progressbar-determinate .p-progressbar-value-animate {
    transition: none !important;
}

</style>